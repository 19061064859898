import React from 'react';
import { styled, Typography, TypographyProps } from '@mui/material';

export type CellTypographyProps = TypographyProps & { wrap?: boolean; shown?: boolean };

const _Typography = React.forwardRef((props: CellTypographyProps, ref: React.ForwardedRef<HTMLSpanElement>) => (
    <Typography component="span" ref={ref} variant="body2" {...props} />
));

_Typography.displayName = '_Typography';

export const CellTypography = styled(_Typography, { shouldForwardProp: (prop: string) => !['wrap'].includes(prop) })`
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: ${({ wrap }) => (wrap ? 'pre-wrap' : 'nowrap')};
    position: ${({ wrap }) => (wrap ? 'static' : 'abolute')};
    overflow-y: auto;
    opacity: ${({ shown }) => Number(shown)};
`;
