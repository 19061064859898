import { List, ListItemProps } from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Children, cloneElement, forwardRef, HTMLAttributes, ReactElement, useRef } from 'react';

const AutocompleteVirtualizedListbox = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
    ({ children, ...props }, ref) => {
        const items = Children.toArray(children);
        const parentRef = useRef<HTMLDivElement>(null);
        const rowVirtualizer = useVirtualizer({
            count: items.length,
            estimateSize: () => 36,
            getScrollElement: () => parentRef.current,
        });

        const height = parentRef.current?.parentElement?.getBoundingClientRect().height || '400px';
        return (
            <div ref={ref} {...props}>
                <div
                    ref={parentRef}
                    style={{
                        minWidth: '400px',
                        width: '600px',
                        height,
                        overflowY: 'auto',
                    }}
                >
                    {height && (
                        <List
                            sx={{
                                height: `${rowVirtualizer.getTotalSize()}px`,
                                position: 'relative',
                            }}
                        >
                            {rowVirtualizer.getVirtualItems().map((virtualItem) => {
                                const item = items[virtualItem.index] as ReactElement<ListItemProps>;
                                return cloneElement(item, {
                                    sx: {
                                        ...item.props.sx,
                                        width: '100%',
                                        height: `${virtualItem.size}px`,
                                        left: 0,
                                        position: 'absolute',
                                        top: 0,
                                        transform: `translateY(${virtualItem.start}px)`,
                                    },
                                });
                            })}
                        </List>
                    )}
                </div>
            </div>
        );
    },
);
AutocompleteVirtualizedListbox.displayName = 'AutocompleteVirtualizedListbox';

export default AutocompleteVirtualizedListbox;
