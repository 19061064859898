import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiError, StaleTicketUpdate, useApi } from '@tymely/api';
import {
    getVisibleArgs,
    IComment,
    IDineshTicketOperations,
    requiresInput,
    isPolicyEvaluated,
    ITicket,
    ITicketTrailCrumb,
    ITicketTrailStatus,
    submittingComments,
    ticketAtom,
    TicketHistoryInfo,
    ticketSessionId,
    IQueueInfo,
} from '@tymely/atoms';
import { MULTIPLE_LOGIC_BOX_ID, NEITHER_LOGIC_BOX_ID, routes } from '@tymely/config';
import { useIntentData } from '@tymely/features/Ticket/Arguments/Sections/ArgumentSections';
import { useNavigate } from 'react-router-dom';

import { useSetAlert } from './alerts.services';
import { useOrganizationsQuery, useSelectedChannels, useSelectedOrganizations } from './organization.services';
import {
    ARGUMENTS_QUERY_KEY,
    DECISION_QUERY_KEY,
    useAgentResponse,
    useSelectedCommentDecisionQuery,
    useSelectedComment,
    useSetCommentIdToFocusOn,
    useSubmitCommentMutation,
} from './comment.services';
import { useArgumentsQuery } from './argument.services';
import { AppMode, useAppMode } from './mode';
import { useCreateHandlingDurationsCrumb } from './ticketTrail.services';
import { useUser } from './auth.services';
import { useFeatureFlags } from './feature.services';

export const MERGE_TICKET_QUERY_KEY = 'ticketsToMerge';
export const TICKET_COUNT_QUERY_KEY = 'ticketCount';
export const OFFLINE_TICKET_QUERY_KEY = 'offline-ticket';
export const ONLINE_TICKET_QUERY_KEY = 'online-ticket';
export const TICKET_QUERY_KEY = 'ticket';
export const IS_TICKET_LOCKED_QUERY_KEY = 'isTicketLocked';

export enum IWaitType {
    POLICY_EVALUATION = 'POLICY_EVALUATION',
    TAGGING = 'TAGGING',
    UNTAGGING = 'UNTAGGING',
    USER_WAITED_FOR_NEW_TICKET = 'USER_WAITED_FOR_NEW_TICKET',
}

export const useTicket = () => useRecoilValue(ticketAtom);
export const useUnsetTicket = () => {
    const unset = useResetRecoilState(ticketAtom);
    const { flushTimers } = useTicketUserWaited();
    return () => {
        flushTimers();
        return unset();
    };
};

export const useTicketSessionId = () => useRecoilValue(ticketSessionId);
export const useSetTicketSessionId = () => {
    const setTicketSessionId = useSetRecoilState(ticketSessionId);
    return useCallback(() => setTicketSessionId(crypto.randomUUID()), [setTicketSessionId]);
};

export const useIsTicketLocked = (ticketId: ITicket['id'] | undefined) => {
    const api = useApi();

    const query = useQuery({
        queryKey: [IS_TICKET_LOCKED_QUERY_KEY, ticketId],
        queryFn: async () => {
            if (!ticketId) return false;
            const isLocked = (await api.get(`ticket/${ticketId}/is_locked`)) as boolean;
            return isLocked;
        },
        enabled: Boolean(ticketId),
    });

    return query;
};

export const sortCommentsByInquiryDate = (comments: IComment[]) =>
    comments
        .slice()
        .sort(
            (comment1, comment2) =>
                new Date(comment1.inquiry_date).valueOf() - new Date(comment2.inquiry_date).valueOf(),
        );

export const useSetTicket = () => {
    const currentTicket = useTicket();
    const setTicket = useSetRecoilState(ticketAtom);
    const setTicketCommentIdToFocusOn = useSetCommentIdToFocusOn();
    const { flushTimers } = useTicketUserWaited();

    return useCallback(
        (ticket: ITicket) => {
            if (currentTicket?.id) {
                flushTimers();
            }
            return setTicket({
                ...ticket,
                comments: sortCommentsByInquiryDate(ticket.comments || []),
            });
        },
        [currentTicket, setTicket, setTicketCommentIdToFocusOn],
    );
};

export const useTicketQuery = (ticketId: ITicket['id'] | undefined) => {
    const api = useApi();
    return useQuery<ITicket>({
        queryKey: [TICKET_QUERY_KEY, ticketId],
        queryFn: async () => {
            if (!ticketId) {
                throw Error();
            }
            return api.get(`ticket/${ticketId}`);
        },
        enabled: Boolean(ticketId),
    });
};

export const useTicketOfflineQuery = (ticketId?: number, enabled?: boolean) => {
    const api = useApi();
    const currentTicket = useTicket();
    const selectedOrganizations = useSelectedOrganizations();
    const client = useQueryClient();
    const setTicket = useSetTicket();
    const { appMode } = useAppMode();

    const query = useQuery<ITicket>({
        queryKey: [OFFLINE_TICKET_QUERY_KEY, ticketId || null],
        queryFn: () =>
            ticketId
                ? api.get(`ticket/${ticketId}`)
                : api.post(
                      'tickets/next/offline',
                      {
                          org_ids: selectedOrganizations.map((org) => org.id),
                      },
                      {
                          params: {
                              current_ticket_id: currentTicket?.id,
                              app_mode: appMode,
                          },
                      },
                  ),
        staleTime: Infinity,
        enabled,
    });

    const { data: ticket } = query;

    useEffect(() => {
        if (ticket && !ticketId) {
            client.setQueryData([OFFLINE_TICKET_QUERY_KEY, ticket.id], ticket);
        }
        if (ticket) {
            setTicket(ticket);
        }
    }, [ticket, ticketId]);

    return query;
};

export const useTicketOnlineQuery = (ticketId?: number, enabled = true) => {
    const { isChat } = useAppMode();
    const navigate = useNavigate();
    const api = useApi();
    const setTicket = useSetTicket();
    const setAlert = useSetAlert();
    const unsetTicket = useUnsetTicket();
    const selectedChannels = useSelectedChannels();
    const timer = useTicketUserWaitedForNewTicket();

    const query = useQuery({
        queryKey: [ONLINE_TICKET_QUERY_KEY],
        queryFn: async () =>
            (ticketId
                ? api.get(`ticket/${ticketId}`)
                : api.post('tickets/next/online', {
                      channels: selectedChannels
                          .filter((ch) => !isChat || ch.organization.config.chat?.enabled)
                          .map((ch) => ({
                              organization_id: ch.organization.id,
                              channel: ch.name,
                          })),
                  })) as Promise<ITicket | null>,
        staleTime: Infinity,
        enabled,
        retry: 0,
    });

    const { data: ticket, error } = query;

    useEffect(() => {
        if (error) {
            setAlert(error instanceof ApiError ? error.detail : error.message, 'error', 0);
        }
    }, [error]);

    useEffect(() => {
        if (ticket === undefined) return;
        if (ticket) {
            setTicket(ticket);
            timer.stop(IWaitType.USER_WAITED_FOR_NEW_TICKET, ticket);
            timer.flushTimers();
        } else {
            navigate(`${routes.onlineTicket}/`);
            unsetTicket();
            timer.start(IWaitType.USER_WAITED_FOR_NEW_TICKET);
        }
    }, [ticket]);

    return query;
};

export const useSetTicketProps = () => {
    const setTicket = useSetRecoilState(ticketAtom);

    return useCallback(
        (props: Partial<ITicket>) => {
            setTicket((ticket) => ticket && { ...ticket, ...props });
        },
        [setTicket],
    );
};

export const useTicketFinalizeSubmit = () => {
    const selectedComment = useSelectedComment();
    const setAlert = useSetAlert();
    const { data: agentResponse } = useAgentResponse();
    const { appMode = AppMode.Training } = useAppMode();
    const { data: decision } = useSelectedCommentDecisionQuery(false);
    const [, setSubmitting] = useRecoilState<{ [key: IComment['id']]: boolean }>(submittingComments);
    const createHandlingDurationsCrumb = useCreateHandlingDurationsCrumb();
    const submitCommentMutation = useSubmitCommentMutation();

    return useCallback(
        async (dryMode = false, afterHandling = true) => {
            try {
                if (!selectedComment) {
                    return Promise.reject('Comment is not selected');
                }

                createHandlingDurationsCrumb(IDineshTicketOperations.USER_SUBMITTED_TICKET);

                setSubmitting((submitting) => ({
                    ...submitting,
                    [selectedComment.id]: true,
                }));

                const postPromise = submitCommentMutation.mutateAsync({
                    commentId: selectedComment.id,
                    dryMode,
                    appMode,
                    responseBody: agentResponse,
                    afterHandling,
                });

                if (decision?.status === 'BETA') {
                    return await postPromise;
                } else {
                    return postPromise
                        .catch(async (e) => {
                            if (e instanceof StaleTicketUpdate) {
                                return;
                            }
                            throw e;
                        })
                        .finally(() => setSubmitting((map) => omit(map, selectedComment.id)));
                }
            } catch (error) {
                setAlert(
                    error instanceof StaleTicketUpdate
                        ? error.message
                        : 'Ticket submission has failed due to an external update',
                    'error',
                );
                return Promise.reject('Ticket submission has failed');
            }
        },
        [selectedComment, agentResponse, setAlert],
    );
};

export const useTicketHistory = () => {
    const currentTicket = useTicket();
    const queryClient = useQueryClient();
    const selectedComment = useSelectedComment();
    const { appMode } = useAppMode();

    const { mutateAsync: mergeMutation } = useMutation({
        mutationFn: ({
            ticketId,
            commentId,
            appMode,
            mergeTicketIds,
        }: {
            ticketId: ITicket['id'];
            commentId: IComment['id'];
            appMode: AppMode;
            mergeTicketIds: ITicket['id'][];
        }) =>
            api.post(
                `ticket/${ticketId}/mark_for_merge?comment_id=${commentId}&mode=${appMode}`,
                mergeTicketIds,
            ) as Promise<ITicket>,
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: [DECISION_QUERY_KEY, selectedComment?.id] }),
                queryClient.invalidateQueries({ queryKey: [ARGUMENTS_QUERY_KEY, selectedComment?.id] }),
            ]);
        },
    });

    const ticketsToMergeQuery = useQuery({
        queryKey: [MERGE_TICKET_QUERY_KEY, currentTicket?.id],
        queryFn: () => api.get(`ticket/${currentTicket.id}/marked_for_merge`) as Promise<number[]>,
        enabled: Boolean(currentTicket),
    });

    const isTicketMarkedForMerge = useCallback(
        (ticket: ITicket) => ticketsToMergeQuery.data?.includes(ticket.id) || false,
        [ticketsToMergeQuery.data],
    );

    const toggleTicketMerge = useCallback(
        async (ticket: ITicket) => {
            if (!selectedComment || !appMode) {
                return;
            }
            const mergeTicketIds = ticketsToMergeQuery.data || [];
            const index = mergeTicketIds.findIndex((t) => t === ticket.id);
            if (index === -1) {
                mergeTicketIds.push(ticket.id);
            } else {
                mergeTicketIds.splice(index, 1);
            }

            return mergeMutation({
                ticketId: currentTicket.id,
                commentId: selectedComment?.id,
                appMode,
                mergeTicketIds,
            }).then(() => {
                queryClient.setQueryData(['ticketsToMerge', currentTicket.id], mergeTicketIds);
            });
        },
        [currentTicket, selectedComment, appMode, queryClient, mergeMutation, ticketsToMergeQuery.data],
    );

    useFeatureFlags();

    const api = useApi();

    const historyQuery = useQuery<TicketHistoryInfo, AxiosError>({
        queryKey: ['ticketHistory', currentTicket?.id],
        queryFn: async () => {
            if (!currentTicket) {
                return Promise.reject('Ticket is not loaded');
            }
            const history = (await api.get(
                `organization/${currentTicket.organization_id}/customer/${currentTicket.origin_customer_id}/ticket_history`,
                {
                    params: {
                        ticket_id: currentTicket?.id,
                        async: true,
                    },
                },
            )) as TicketHistoryInfo;

            return {
                ...history,
                tickets: history.tickets
                    ? history.tickets.sort(
                          (t1, t2) => new Date(t1.inquiry_date).valueOf() - new Date(t2.inquiry_date).valueOf(),
                      )
                    : [],
            };
        },
        enabled: Boolean(currentTicket) && !currentTicket.organization.disabled,
    });
    return {
        ...historyQuery,
        history: historyQuery.data,
        ticketsToMerge: ticketsToMergeQuery.data,
        toggleTicketMerge,
        isTicketMarkedForMerge,
    };
};

export const useTicketCountQuery = () => {
    const api = useApi();
    const selectedChannels = useSelectedChannels();
    const channelDefs = selectedChannels.map((ch) => ({ organization_id: ch.organization.id, channel: ch.name }));

    return useQuery<IQueueInfo[]>({
        queryKey: [TICKET_COUNT_QUERY_KEY],
        queryFn: () => api.post('online/queue-info', { channels: channelDefs }),
        enabled: selectedChannels.length > 0,
    });
};

export const useIsSecondTouchTicket = (ticket: ITicket) => {
    return useMemo(() => {
        const comments = sortCommentsByInquiryDate(ticket?.comments || []);
        const customerInquiry = comments.find(({ is_customer }) => {
            return is_customer;
        });
        const agentResponse = comments.find(
            ({ is_customer, is_public, inquiry_date }) =>
                customerInquiry && inquiry_date > customerInquiry.inquiry_date && !is_customer && is_public !== false,
        );
        if (!agentResponse) return false;

        return comments.some(({ is_customer, inquiry_date }) => {
            return inquiry_date > agentResponse.inquiry_date && is_customer;
        });
    }, [ticket]);
};

export const useTicketReset = () => {
    const api = useApi();
    const ticket = useTicket();
    const setTicket = useSetTicket();
    const queryClient = useQueryClient();
    const { updateDecision } = useSelectedCommentDecisionQuery(false);

    return useCallback(async () => {
        if (!ticket) {
            return Promise.reject('Ticket is not set');
        }
        const updatedTicket = (await api.put(`ticket/${ticket.id}/reset`, {})) as ITicket;
        setTicket(updatedTicket);
        queryClient.invalidateQueries({ queryKey: [MERGE_TICKET_QUERY_KEY] });
        setTimeout(() => {
            for (const comment of ticket.comments) {
                queryClient.invalidateQueries({ queryKey: [ARGUMENTS_QUERY_KEY, comment.id] });
            }
        });
        updateDecision(null);

        return updatedTicket;
    }, [ticket, setTicket, updateDecision]);
};

const ACTIONABLE_STATUSES = ['LIVE', 'BETA', 'EXTERNAL_TANDEM'];

export const useTicketActions = () => {
    const comment = useSelectedComment();
    const ticket = useTicket();
    const policyEvaluated = useRecoilValue(isPolicyEvaluated);
    const { appMode, isOnline } = useAppMode();
    const { isIntentSupported } = useIntentData();
    const { data: decision } = useSelectedCommentDecisionQuery(policyEvaluated);
    const { data: args, isLoading: isArgsLoading } = useArgumentsQuery({
        commentId: comment?.id,
        enabled: false,
    });
    const { isChat } = useAppMode();
    const isChatOrg = ticket?.organization?.config?.chat?.enabled;
    const decisionHasActions = !!decision?.action_executions?.length;
    const decisionIsActionable = ACTIONABLE_STATUSES.includes(decision?.status ?? '');
    const commentAlreadySubmitted = !!comment?.submitted_at;
    const textArguments = useMemo(
        () => args?.filter(({ arg_type }) => ['TEXT_ARGUMENT', 'INFO_ARGUMENT', 'USER_INPUT'].includes(arg_type)),
        [args],
    );

    const textArgsFilled = useMemo(
        () =>
            !isArgsLoading &&
            (textArguments?.length ? getVisibleArgs(textArguments).every((arg) => !requiresInput(arg)) : true),
        [textArguments, isArgsLoading],
    );

    if (appMode === AppMode.Admin) {
        const cannotSubmitExplanation = !decisionHasActions
            ? 'Decision has no actions'
            : commentAlreadySubmitted && !isChat && !isChatOrg
              ? `Already submitted at ${
                    comment?.submitted_at ? new Date(comment.submitted_at).toLocaleString() : 'unknown'
                }`
              : undefined;
        return {
            reviewEnabled: true,
            nextEnabled: true,
            saveEnabled: decisionHasActions,
            submitEnabled: !cannotSubmitExplanation,
            cannotSubmitExplanation,
        };
    } else if (appMode !== AppMode.Online) {
        return {
            reviewEnabled: appMode !== AppMode.QA,
            nextEnabled: appMode !== AppMode.QA || (comment?.selected_intent_id && textArgsFilled),
            saveEnabled: appMode === AppMode.QA,
            submitEnabled: false,
            cannotSubmitExplanation: 'Ticket can only be submitted in Online mode',
        };
    }

    const latestCustomerCommentInquiryDate = ticket?.comments
        .filter((c) => c.is_customer)
        .sort((a, b) => b.id - a.id)[0]?.inquiry_date;

    const cannotSubmitExplanation = !textArgsFilled
        ? 'All text arguments must be filled'
        : !policyEvaluated && textArguments?.length
          ? 'Policy must be evaluated'
          : !decisionHasActions
            ? 'Decision has no actions'
            : !decisionIsActionable
              ? `Decision is ${decision?.status}`
              : decision?.execution_status !== 'PENDING'
                ? `Decision is ${decision?.execution_status}`
                : !(ticket?.live ?? true)
                  ? 'Ticket is not live.'
                  : !isOnline
                    ? 'Ticket can only be submitted in Online mode'
                    : commentAlreadySubmitted && !isChat
                      ? `Already submitted at ${comment?.submitted_at ? new Date(comment.submitted_at).toLocaleString() : 'unknown'}`
                      : comment?.inquiry_date !== latestCustomerCommentInquiryDate
                        ? 'Not the latest comment'
                        : undefined;

    const ambiguousIntent =
        comment?.selected_intent_id === MULTIPLE_LOGIC_BOX_ID || comment?.selected_intent_id === NEITHER_LOGIC_BOX_ID;
    const reviewEnabled = textArgsFilled || ambiguousIntent;

    const nextEnabled =
        !isIntentSupported || decision?.status === 'SKIP' || !decisionHasActions || !!comment?.submitted_at;
    const saveEnabled = textArgsFilled && decisionHasActions && decision?.status === 'TANDEM';

    return {
        reviewEnabled,
        nextEnabled,
        saveEnabled,
        submitEnabled: !cannotSubmitExplanation,
        cannotSubmitExplanation,
    };
};

export const useTicketOrganization = () => {
    const ticket = useTicket();
    const { data: orgs } = useOrganizationsQuery();
    return useMemo(
        () => orgs?.find((org) => org.id === ticket?.organization_id) || ticket?.organization,
        [ticket?.organization_id],
    );
};

export const useFeedbackReport = () => {
    const api = useApi();
    const user = useUser();
    const setAlert = useSetAlert();
    return useMutation({
        mutationFn: () => api.post('reports/agents-feedback?async=true', { send_to: user?.email ? [user.email] : [] }),
        onSuccess: async () => {
            setAlert("Feedback report is being created. You'll receive an email when it's ready.");
        },
        onError: (error: ApiError) => {
            setAlert(`Failed to create tandem report. ${error.message}`, 'error');
        },
    });
};

export const useUpsertNotes = () => {
    const api = useApi();
    return useMutation<
        unknown,
        AxiosError,
        {
            commentId: IComment['id'];
            notes: ITicket['notes'];
        }
    >({
        mutationFn: ({ commentId, notes }) => api.put(`ticket/${commentId}/notes`, notes),
    });
};

//

type TimerHistoryRecord = {
    type: IWaitType;
    waitedFor: number;
    start: Date;
    stop: Date;
};

let timersHistory: TimerHistoryRecord[] = [];
let waitTimes: Partial<Record<IWaitType, number>> = {};
let startTimes: Partial<Record<IWaitType, Date>> = {};
let waitTicketTrail: ITicketTrailCrumb;

const useTicketTimes = (operation: IDineshTicketOperations) => {
    const api = useApi();
    const user = useUser();
    const currentTicket = useTicket();
    const selectedComment = useSelectedComment();
    const { data: decision } = useSelectedCommentDecisionQuery(false);

    const start = React.useCallback((type: IWaitType) => {
        if (!startTimes[type]) {
            startTimes[type] = new Date();
        }
    }, []);

    const stop = React.useCallback(
        (type: IWaitType, ticket?: ITicket) => {
            const _ticket = ticket ?? currentTicket;
            const start = startTimes[type];
            if (!start || !_ticket) {
                return;
            }
            waitTicketTrail = {
                operation,
                ticket_id: _ticket.id,
                comment_id: selectedComment?.id,
                status: ITicketTrailStatus.SUCCESS,
                origin_ticket_id: _ticket.original_id_from_client,
                organization_id: _ticket.organization_id,
                additional_data: {
                    workflow_id: decision?.workflow_id,
                    intent_id: selectedComment?.selected_intent_id,
                },
            };

            const now = new Date();
            const waitedFor = Math.round(now.getTime() - start.getTime());
            timersHistory.push({ type, waitedFor, start, stop: now });
            waitTimes[type] = (waitTimes[type] || 0) + waitedFor;
            startTimes = omit(startTimes, type);

            return waitedFor;
        },
        [currentTicket],
    );

    const waitFor = (type: IWaitType, promise: Promise<unknown>) => {
        start(type);
        return promise.finally(() => stop(type));
    };

    const flushTimers = () => {
        if (!isEmpty(waitTimes)) {
            api.post('ticket-trail', {
                ...waitTicketTrail,
                additional_data: {
                    ...waitTicketTrail.additional_data,
                    ...waitTimes,
                    total: Object.entries(waitTimes).reduce((total, [_, time]) => total + time, 0),
                    timers: timersHistory,
                    username: user?.username,
                },
                triggered_by: 'USER',
            });

            waitTimes = {};
            startTimes = {};
        }
        timersHistory = [];
    };

    return {
        start,
        stop,
        waitFor,
        flushTimers,
    };
};

const useTicketUserWaitedForNewTicket = () => useTicketTimes(IDineshTicketOperations.USER_WAITED_FOR_NEW_TICKET);

export const useTicketUserWaited = () => useTicketTimes(IDineshTicketOperations.USER_WAITED);

export const useWaitWhileLoading = (type: IWaitType, isLoading: boolean) => {
    const timer = useTicketUserWaited();
    useEffect(() => {
        if (isLoading) {
            timer.start(type);
        } else {
            timer.stop(type);
        }
    }, [isLoading]);
};

export const useEnqueueTickets = () => {
    const api = useApi();
    return useMutation({
        mutationFn: ({
            ticketIds,
            channels,
        }: {
            ticketIds: Array<ITicket['id']>;
            channels: { organization_id: number; channel: string }[];
        }) => api.post<void>('tickets/enqueue', { ticket_ids: ticketIds, channels: channels }),
    });
};
