import React from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { Key } from 'ts-key-enum';
import { Box, styled } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { newTheme, routes } from '@tymely/config';
import { useKeys } from '@tymely/utils';
import { AlertsBar, KeymapHelpCard, Loader } from '@tymely/components';
import { AppBar as AppBarNew } from '@tymely/features/AppBar';

const Container = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    height: '100vh',
    width: '100vw',
    overflow: 'auto',
});

const Main = styled(Box)(() => ({
    backgroundColor: 'rgb(231, 235, 240)',
    position: 'relative',
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
}));

const PolicyEditorPage = React.lazy(() => import('@tymely/pages/PolicyEditor'));
const GraphPolicyEditorPage = React.lazy(() => import('@tymely/pages/GraphPolicyEditor'));
const TicketOnlinePage = React.lazy(() => import('@tymely/pages/TicketOnlinePage'));
const TicketOfflinePage = React.lazy(() => import('@tymely/pages/TicketOfflinePage'));
const ArgumentsEditorPage = React.lazy(() => import('@tymely/pages/Arguments'));
const ActionsEditorPage = React.lazy(() => import('@tymely/pages/Actions'));
const TemplateEditorPage = React.lazy(() => import('@tymely/pages/TemplateEditor'));
const OrganizationEditorPage = React.lazy(() => import('@tymely/pages/OrganizationEditor'));
const AdminRetoolPage = React.lazy(() => import('@tymely/pages/Admin/AdminRetoolPage'));
const DemoPage = React.lazy(() => import('@tymely/pages/Demo'));
const PolicyDemoPage = React.lazy(() => import('@tymely/pages/PolicyDemo'));
const ChatDemoPage = React.lazy(() => import('@tymely/pages/ChatDemo'));
const PromptEditorPage = React.lazy(() => import('@tymely/pages/PromptEditor'));
const NotFoundPage = React.lazy(() => import('@tymely/pages/NotFound'));
const TicketExchangePage = React.lazy(() => import('@tymely/pages/TicketExchangePage'));
const TandemReportEditorPage = React.lazy(() => import('@tymely/pages/TandemReportEditorPage'));

const PageSuspense = (props: { children: React.ReactNode }) => {
    return (
        <React.Suspense
            fallback={
                <Loader
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                    }}
                />
            }
        >
            {props.children}
        </React.Suspense>
    );
};

export const AppLayout = () => {
    const hasBar = !useMatch(`${routes.demo}/chat`);

    const [keymapOpen, setKeymapOpen] = React.useState(false);
    useKeys([Key.Control, Key.Alt, 'KeyH'], () => setKeymapOpen(true));

    return (
        <Container>
            {hasBar && (
                <CssVarsProvider theme={newTheme}>
                    <AppBarNew />
                </CssVarsProvider>
            )}
            <Main component="main">
                <AlertsBar />
                <Routes>
                    <Route index element={<Navigate to={routes.ticket} />} />
                    <Route
                        path={`${routes.policyEditor}/:organizationId?/:intentId?/:workflowId?`}
                        element={
                            <PageSuspense>
                                <PolicyEditorPage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={`${routes.graphPolicyEditor}/:organizationId?/:intentId?/:versionId?`}
                        element={
                            <PageSuspense>
                                <GraphPolicyEditorPage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={`${routes.tandemReports}/:reportId`}
                        element={
                            <PageSuspense>
                                <TandemReportEditorPage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={routes.argumentMetadataView}
                        element={
                            <PageSuspense>
                                <ArgumentsEditorPage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={routes.actionMetadataView}
                        element={
                            <PageSuspense>
                                <ActionsEditorPage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={routes.organizationEditor}
                        element={
                            <PageSuspense>
                                <OrganizationEditorPage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={`${routes.templateEditor}/:templateId?`}
                        element={
                            <PageSuspense>
                                <TemplateEditorPage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={`${routes.ticket}/:ticketId?`}
                        element={
                            <PageSuspense>
                                <TicketOfflinePage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={`${routes.onlineTicket}/:ticketId?`}
                        element={
                            <PageSuspense>
                                <TicketOnlinePage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={`${routes.demo}/policy-editor`}
                        element={
                            <PageSuspense>
                                <PolicyDemoPage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={`${routes.demo}/chat`}
                        element={
                            <PageSuspense>
                                <ChatDemoPage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={`${routes.demo}/:ticketId?`}
                        element={
                            <PageSuspense>
                                <CssVarsProvider theme={newTheme}>
                                    <DemoPage />
                                </CssVarsProvider>
                            </PageSuspense>
                        }
                    />
                    <Route path={routes.admin}>
                        <Route
                            index
                            element={
                                <PageSuspense>
                                    <AdminRetoolPage />
                                </PageSuspense>
                            }
                        />
                    </Route>
                    <Route
                        path={routes.promptEditor}
                        element={
                            <PageSuspense>
                                <PromptEditorPage />
                            </PageSuspense>
                        }
                    />
                    <Route
                        path={routes.ticketExchange}
                        element={
                            <PageSuspense>
                                <TicketExchangePage />
                            </PageSuspense>
                        }
                    />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </Main>
            <KeymapHelpCard open={keymapOpen} onClose={() => setKeymapOpen(false)} />
        </Container>
    );
};
