import { Nullable, PartialBy } from '@global/types';
import { XYPosition } from 'reactflow';

import { IArgumentMetadata } from './argument.atoms';
import { IPolicySet } from './policySet.atoms';
import { IOrganization } from './organization.atoms';

export const SpecialValues = ['neither', 'unspecified', 'unknown', 'none'] as const;

export type SpecialValue = (typeof SpecialValues)[number];

export const ComparePredicates = [
    'equals',
    'not_equals',
    'greater_than',
    'less_than',
    'greater_or_equals',
    'less_or_equals',
    'match_regex',
    'not_match_regex',
    'length_equals',
    'length_not_equals',
    'longer_than',
    'shorter_than',
    'days_passed_greater_or_equals',
    'days_passed_less',
] as const;

export type ComparePredicate = (typeof ComparePredicates)[number];

export type SetPredicate =
    | 'is_subset'
    | 'is_not_subset'
    | 'intersects'
    | 'not_intersects'
    | 'is_superset'
    | 'not_superset';

export const IdentityPredicates = [
    'is_true',
    'is_false',
    'has_items',
    'is_empty',
    'exists',
    'missing',
    'is_neither',
    'is_unspecified',
    'is_unknown',
] as const;

export const CollectionPredicates = [
    'is_in',
    'not_in',
    'intersects',
    'not_intersects',
    'is_subset',
    'is_not_subset',
    'is_superset',
    'not_superset',
] as const;

export type IdentityPredicate = (typeof IdentityPredicates)[number];

export type CollectionPredicate = 'is_in' | 'not_in';

export type RangePredicate = 'in_range' | 'out_of_range';

export type DatePedicate = 'before_date' | 'after_date';

export type Predicate =
    | IdentityPredicate
    | ComparePredicate
    | CollectionPredicate
    | RangePredicate
    | SetPredicate
    | DatePedicate;

export interface IConditionBase<P extends Predicate = IdentityPredicate> {
    id?: number;
    uiId: string;
    predicate: P;
    value: { value?: any; special: SpecialValue[] } | null;
    order: number;
    additional_data?: Record<string, unknown>;
}

export type ConditionValue<V> = {
    value: Nullable<V>;
    special: SpecialValue[];
};

export interface IConditionWithValue<P extends Predicate, V> extends IConditionBase<P> {
    value: ConditionValue<V>;
}

export type ICompareCondition = IConditionWithValue<ComparePredicate, string | number>;

export type IDateCondition = IConditionWithValue<'before_date' | 'after_date', string>;

export type IConditionWithCollection = IConditionWithValue<CollectionPredicate, string[]>;

export type IConditionWithRange<V extends number = number> = IConditionWithValue<
    RangePredicate,
    { lower_bound: V | undefined; upper_bound: V | undefined }
>;

export type ISetCondition = IConditionWithValue<SetPredicate, string[]>;

export type IPolicyConditionWithValue =
    | ICompareCondition
    | IConditionWithCollection
    | IConditionWithRange
    | ISetCondition
    | IDateCondition;

export type IPolicyCondition = IConditionBase | IPolicyConditionWithValue;

export interface WithArgMetadata {
    argument_metadata: IArgumentMetadata;
    argument_metadata_id: IArgumentMetadata['id'];
}

export type IPolicyConditionWithArgMetadata = IPolicyCondition & WithArgMetadata;

export const isBaseCondition = (cond: Pick<IPolicyCondition, 'predicate'>): cond is IConditionBase => {
    return IdentityPredicates.includes(cond.predicate);
};

export interface IActionMetadata {
    id: number;
    title: string;
    description: string;
    executor_name: string;
}

export interface IPolicyAction {
    id: number;
    action_metadata_id: IActionMetadata['id'];
    action_metadata: IActionMetadata;
    order: number;
    args: Record<string, string | number>;
    description?: string;
}

export const workflowStatuses = ['LIVE', 'BETA', 'EXTERNAL_TANDEM', 'TANDEM', 'SKIP', 'DEV', 'OFF'] as const;
export type WorkflowStatus = (typeof workflowStatuses)[number];

export const executionStatuses = ['PENDING', 'SCHEDULED', 'RUNNING', 'SUCCESS', 'CANCELLED', 'FAILURE'] as const;
export type ExecutionStatus = (typeof executionStatuses)[number];

export interface IWorkflow {
    id: number;
    policy_set_id: IPolicySet['id'];
    organization_id: IOrganization['id'];
    deleted_at?: string;
    title: string;
    order: number;
    status: WorkflowStatus;
    auto: boolean;
    conditions: IPolicyConditionWithArgMetadata[];
    actions: IPolicyAction[];
}

export interface IWfPolicy {
    orgId: IOrganization['id'];
    intentId: IPolicySet['id'];
    workflows: IWorkflow[];
}

export interface IGrPolicyNodeBase {
    id: string;
    policy_id?: number;
    notes?: string;
    position?: XYPosition | null;
}

export interface IGrPolicyArgumentNode extends IGrPolicyNodeBase {
    arg_metadata_id: number;
    workflow: undefined; // allows to differentiate from Decision nodes.
}

export interface IGrPolicyDecisionNode extends IGrPolicyNodeBase {
    arg_metadata_id: undefined; // allows to differentiate from Argument nodes.
    workflow: Pick<IWorkflow, 'id' | 'status' | 'title' | 'auto'> & {
        actions: Omit<IPolicyAction, 'action_metadata'>[];
    };
}

export type GrPolicyNode = IGrPolicyArgumentNode | IGrPolicyDecisionNode;

export interface IGrPolicyEdge {
    id: string;
    policy_id?: number;
    source_id: string;
    target_id: string;
    order: number;
    condition?: Omit<IPolicyCondition, 'uiId' | 'argument_metadata'>;
}

export interface IGrPolicy {
    id: number;
    created_date: string;
    org_policy_set_id: number;
    policy_set_id: number;
    initial_state: Nullable<string>;
    graph_only: boolean;
    graph: {
        nodes: GrPolicyNode[];
        edges: IGrPolicyEdge[];
    };
}

export interface IPolicyVersion {
    id: number;
    policy_id: IGrPolicy['id'];
    created_at: string;
}

export type UiId<T> = T & { uiId: string };
export type IUiAction = UiId<PartialBy<IPolicyAction, 'id'>>;
export type IUiWorkflow = UiId<
    PartialBy<
        Omit<IWorkflow, 'actions' | 'organization_id' | 'policy_set_id'> & {
            actions: IUiAction[];
        },
        'id'
    >
>;
export type IUiPolicy = Omit<IWfPolicy, 'workflows'> & {
    workflows: IUiWorkflow[];
};

export const conditionTitles = {
    dont_care: "Don't care",
    is_true: 'Yes',
    is_false: 'No',
    has_items: 'Not empty',
    is_empty: 'Empty',
    exists: 'Exists',
    missing: 'Missing',
    is_neither: 'Neither',
    is_unspecified: 'Unspecified',
    is_unknown: 'Unknown',
    equals: 'Is',
    not_equals: 'Is not',
    greater_than: 'Greater than',
    less_than: 'Less than',
    greater_or_equals: 'Greater or equals',
    less_or_equals: 'Less or equals',
    before_date: 'Before',
    after_date: 'After',
    in_range: 'Between',
    out_of_range: 'Not between',
    is_in: 'In',
    not_in: 'Not in',
    is_subset: 'Subset of',
    is_not_subset: 'Not Subset of',
    is_superset: 'Superset of',
    not_superset: 'Not Superset of',
    intersects: 'Intersects',
    not_intersects: 'Does not intersect',
    match_regex: 'Matches',
    not_match_regex: "Doesn't match",
    length_equals: 'Length is',
    length_not_equals: 'Length is not',
    longer_than: 'Longer than',
    shorter_than: 'Shorter than',
    days_passed_greater_or_equals: 'Days passed >=',
    days_passed_less: 'Days passed <',
};
